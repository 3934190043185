import React from 'react';
import Layout from '../components/layout';

const About = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{process.env.GATSBY_LANGUAGE === "no" ? "Bransjeløsninger.no" : "Om Branschlösningar.se"}</h1>

          {process.env.GATSBY_LANGUAGE === "no" ? <>
            <p>Bransjeløsninger er en samlingsplass for anerkjente forretningssystem- og CRM-løsninger basert på Microsoft Dynamics NAV, AX og CRM - oversiktlig og lettnavigert! Her kan sluttkunder og innkjøpskonsulenter finne en løsning og en partner som er spesialiserte innen en bestemt bransje. Vi arbeider kontinuerlig med søkeordoptimering og trafikkanalysering av nettstedet for å få best mulig gjennomslag i søkemotorene.</p><h2 className='mt-3'>Utvikling og fremtid</h2><p>Antallet løsninger og besøkere har siden starten hatt en meget sterk tilvekst, og vi har i løpet av det seneste året ekspandert og dekker nå Sverige og Norge. Dette gir oss bredden og styrken til å nå ut med dine løsninger til en meget stor målgruppe, og lar deg eksponere løsningene dine også utenfor Norge.</p><p>I tillegg til komplette forretnings- og CRM-system har Bransjeløsninger.no siden desember også levert tilleggsløsninger og addons. Dette gjør at Bransjeløsninger.no kan tilby et komplett utvalg av løsninger slik at du som Dynamics-kunde skal få ut hele potensialet av din Dynamics-løsning. Finner du ikke en løsning som passer deg får du gjerne kontakte oss, så vil vi i samarbeid med Microsoft hjelpe deg.</p><h2 className='mt-3'>Vil du som forhandler synes her?</h2><p>Vil du som Dynamics-partner legge opp en løsning så kontakter du oss. Videre fyller du ut en enkelt sjekkliste direkte her på nettsiden. Deretter legger vi opp løsningen din umiddelbart. Du kan også velge å få løsningen din publisert i Sverige, og vi kan hjelpe deg med oversettelsen av tekster. Mangler du en riktig god referansecase, så har vi ressurser til dette også.
            </p>
          </> : <>
            <p>Branschlösningar.se är en samlingsplats för erkänt bra
              affärssystemlösningar och lösningar baserade på affärssystem från
              MICROSOFT, VISMA, NETSUITE, JEEVES och IFS samt andra affärssystem.
              De presenteras överskådligt och lättnavigerat! Här kan slutkunder
              och upphandlingskonsulter hitta en lösning och en partner som är
              specialiserade på en specifik bransch.</p>
            <p>
              Vi arbetar kontinuerligt med att sökordsoptimera och analysera
              trafiken till siten för att få bästa möjliga genomslag hos
              sökmotorerna.
            </p>
            <figure className="bg-gray-50 w-4/5 p-4 rounded-md m-12">
              <blockquote>
                <p className="text-2xl">
                  För oss var det en självklarhet att ta med alla våra
                  Dynamics-lösningar på Branschlösningar.se.Det ger oss en utmärkt
                  exponering av våra lösningar och driver trafik till vår site.
                </p>
              </blockquote>
              <figcaption className="text-xl text-right">
                -Per Lundström, CGI
              </figcaption>
            </figure>
            <figure className="bg-gray-50 w-4/5 p-4 rounded-md m-12">
              <blockquote>
                <p className="text-2xl">
                  Det kändes självklart att vara med på Branschlösningar i både
                  Sverige, Norge och Danmark. I Norge fick vi vår första affär
                  tack vare Bransjeløsninger.no, det var innan vi ens hade någon
                  norsk återförsäljare.
                </p>
              </blockquote>
              <figcaption className="text-xl text-right">
                -Johnny Krogh, CFO JMA A/S
              </figcaption>
            </figure>
            <h2 className='mt-3'>Utveckling & framtid</h2>
            <p>
              Antalet lösningar och besökare har sedan starten haft en mycket
              stark tillväxt och vi täcker också Norge och Danmark. Det ger oss en
              bredd och styrka att nå ut med era lösningar till en mycket stor
              målgrupp och låter er exponera era lösningar inte bara i Sverige.
            </p>
            <h2 className='mt-3'>Vill ni synas här?</h2>
            <p>
              Har ni en lösning som ni tycker borde synas på Branschlöningar.se?
              Kontakta oss så översänder vi en checklista för ifyllande och sedan
              lägger vi upp er lösning omgående.
            </p>
          </>}
        </div>
      </div>
    </Layout>
  );
};

export default About;
